<template>
  <div class="mb-4">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "FormGroup",
}
</script>
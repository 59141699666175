<template>
      <span class="text-danger small w-100 text-center d-block mt-1">
    <slot>
    </slot>
  </span>
</template>

<script>
export default {
    name: "InputError",
}
</script>
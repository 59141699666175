<template>
  <contenedor-centrado class="justify-content-center">
    <p class="text-center fs-4">No se ha encontrado el recurso</p>
  </contenedor-centrado>
</template>
<script>
import ContenedorCentrado from "@/components/generales/layout/ContenedorCentrado.vue";

export default {
  name: "NotFoundResource",
  components: {ContenedorCentrado},
}
</script>
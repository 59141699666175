<template>
    <label class="form-label block font-medium text-center w-100">
        <slot></slot>
    </label>
</template>

<script>
    export default {
        name: "FormLabel",
    }
</script>